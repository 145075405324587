import moment from "moment";
import _ from "lodash";

export default {
  props: {
    range: { type: Object },
    week: { type: Number },
    events: { type: Array, required: true },
    holidays: { type: Array, default: () => [[], [], [], [], [], [], []] },
  },
  computed: {
    days() {
      const beg = this.range
        ? moment(this.range.beg).add(this.week, "weeks")
        : moment();
      beg.startOf("isoWeek");
      return Array.from({ length: 7 }, (_, i) =>
        beg.clone().add(i, "days").toDate()
      );
    },
    noAgenda() {
      return !this.holidays.some((h) => h.length);
    },
  },
  methods: {
    setOccurrence(templateId, payload) {
      const event = this.events.find((ev) => ev.templateId === templateId);
      this.$emit(
        "setInstance",
        _.pickBy(
          {
            ...event,
            date: event.date ?? this.days[event.payload.when.day - 1],
            payload: event.isInstance
              ? { ...event.payload, ...payload }
              : payload,
          },
          _.identity
        )
      );
    },
    cancelOccurrence(templateId) {
      const event = this.events.find((ev) => ev.templateId === templateId);
      this.$emit(
        "setInstance",
        _.pickBy(
          {
            ...event,
            date: event.date ?? this.days[event.payload.when.day - 1],
            payload: event.isInstance ? event.payload : null,
            isCancelled: true,
          },
          _.identity
        )
      );
    },
    restoreOccurrence(templateId) {
      const event = this.events.find((ev) => ev.templateId === templateId);
      if (event.isInstance) {
        this.$emit(
          "setInstance",
          _.pickBy(
            {
              ...event,
              isCancelled: null,
            },
            _.identity
          )
        );
        return;
      }
      this.$emit("unsetInstance", { templateId, date: event.date });
    },
    deleteOccurrence(templateId) {
      const event = this.events.find((ev) => ev.templateId === templateId);
      this.$emit("unsetInstance", { templateId, date: event.date });
    },
  },
};
