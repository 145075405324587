<template>
  <v-card
    data-draft="times"
    flat
    tile
    class="overflow-auto d-flex flex-column"
    :max-width="viewport.clientWidth"
    :max-height="viewport.clientHeight"
    @scroll="setScrollPosition"
  >
    <div class="d-flex flex-column-reverse">
      <div class="d-flex flex-row">
        <div class="sticky" style="left: 0; z-index: 1">
          <Times
            align-bottom
            :times="times"
            :box-height="boxHeight"
            format="h A"
          />
        </div>

        <TimeEvents
          :client-width="viewport.clientWidth"
          :box-width="boxWidth"
          :box-height="boxHeight"
          :rows="times.length"
          :columns="days.length"
          :events="events"
          :staff="staff"
          @insertSeries="(v) => $emit('updateSeries', v)"
          @updateSeries="(id, v) => $emit('updateSeries', v, id)"
          @deleteSeries="(id) => $emit('deleteSeries', id)"
        />
      </div>

      <div class="sticky" :style="`top: 0; z-index: 1`">
        <Days :days="days" :box-width="boxWidth" />
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="flex-grow-1" style="visibility: hidden" />
      <slot name="footer" />
      <div class="flex-grow-1" style="visibility: hidden" />
    </div>
  </v-card>
</template>

<style>
[data-y-index] {
  scroll-margin-top: 92px;
}
</style>

<script>
import Times from "./Times";
import TimeEvents from "./TimeEvents";
import Days from "./Days";
import BaseEventsView from "./BaseEventsView";
export default {
  name: "TimeEventsView",
  extends: BaseEventsView,
  components: {
    Times,
    TimeEvents,
    Days,
  },
  props: {
    minBoxWidth: { type: Number, default: 0 },
    maxBoxWidth: { type: Number, default: Infinity },
    minBoxHeight: { type: Number, default: 0 },
    maxBoxHeight: { type: Number, default: Infinity },
    viewport: { type: Object, required: true },
    staff: { type: Array, required: true },
  },
  data: () => ({
    times: Array.from({ length: 23 }, (_, i) => (i + 1) * 60),
    scrollTop: 0,
    scrollLeft: 0,
  }),
  computed: {
    fillX() {
      const times = this.$refs.times?.$el.clientWidth ?? 73;
      return times + 16;
    },
    fillY() {
      const header = this.$refs.header?.$el.clientHeight ?? 92;
      return header;
    },
    boxWidth() {
      const boxWidth = (this.viewport.clientWidth - this.fillX) / 7;
      return Math.floor(
        Math.min(this.maxBoxWidth, Math.max(this.minBoxWidth, boxWidth))
      );
    },
    boxHeight() {
      const boxHeight = (this.viewport.clientHeight - this.fillY) / 15;
      return Math.floor(
        Math.min(this.maxBoxHeight, Math.max(this.minBoxHeight, boxHeight))
      );
    },
  },
  methods: {
    setScrollPosition() {
      this.scrollTop = this.$el.scrollTop;
      this.scrollLeft = this.$el.scrollLeft;
    },
  },
  async mounted() {
    // find the earliest event time
    await this.$nextTick();
    const time = Math.min(...this.events.map((ev) => ev.payload.when.time));
    if (!isFinite(time)) return;
    const index = Math.floor(time / 60);
    const el = this.$el.querySelector(`[data-y-index="${index}"]`);
    if (el.scrollIntoView) el.scrollIntoView(true);
  },
  activated() {
    this.$el.scrollTop = this.scrollTop;
    this.$el.scrollLeft = this.scrollLeft;
  },
};
</script>
