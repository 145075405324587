<template>
  <v-card flat tile class="d-flex d-flex-row align-stretch" v-bind="$props">
    <div class="cal-margin-label d-flex align-center"><slot></slot></div>
    <div class="cal-margin-tick" />
  </v-card>
</template>

<style>
.cal-margin-label {
  min-width: 64px;
  max-width: 64px;
}

.cal-margin-tick {
  max-width: 9px;
  min-width: 9px;
  border-bottom: 1px solid #bdbdbd;
}

.no-tick .cal-margin-tick {
  border-bottom: none;
}
</style>

<script>
export default {
  name: "GridMargin",
  props: ["height", "minHeight", "maxHeight"],
};
</script>
