<template>
  <div class="d-flex flex-column">
    <!-- days of the week -->
    <div class="d-flex flex-row">
      <GridMargin class="no-tick" />
      <v-card
        v-for="(day, i) in days"
        :key="`day-${i}`"
        class="d-flex flex-column align-center"
        :min-width="i + 1 < days.length ? boxWidth : boxWidth + 1"
        :data-iso-weekday="moment(day).isoWeekday()"
        :data-month-date="moment(day).date()"
        flat
        tile
      >
        <Day :range="range" :date="day" />
      </v-card>
    </div>

    <!-- agenda/holidays -->
    <div v-if="noAgenda" class="d-flex flex-row no-agenda">
      <GridMargin />
      <Agenda
        v-for="(day, i) in days"
        :key="`day-${i}`"
        :width="i + 1 < days.length ? boxWidth : boxWidth + 1"
      />
    </div>

    <div v-else class="d-flex flex-row flex-grow-1">
      <GridMargin />
      <Agenda
        v-for="(day, i) in days"
        :key="`day-${i}`"
        :width="i + 1 < days.length ? boxWidth : boxWidth + 1"
        :agenda="holidays[i]"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import GridMargin from "./GridMargin";
import Day from "./Day";
import Agenda from "./Agenda";
export default {
  name: "Days",
  props: {
    range: { type: Object },
    days: { type: Array, required: true },
    holidays: { type: Array },
    boxWidth: { type: Number, required: true },
    noAgenda: { type: Boolean, default: true },
  },
  components: {
    GridMargin,
    Day,
    Agenda,
  },
  methods: {
    moment(date) {
      return moment(date);
    },
  },
};
</script>
