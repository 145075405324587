<template>
  <v-card
    flat
    tile
    width="52"
    v-if="range && isToday"
    class="calendar-day today"
    :disabled="range && disabled"
  >
    <div ref="dayOfTheWeek" class="primary--text text-overline">
      {{ $d(date, "dayOfTheWeek") }}
    </div>

    <v-avatar
      ref="dayOfTheMonth"
      style="margin-top: -8px; border-radius: 50%"
      color="primary"
      class="white--text text-h5"
      size="46"
    >
      {{ $d(date, "dayOfTheMonth") }}
    </v-avatar>
  </v-card>

  <v-card
    flat
    tile
    width="52"
    v-else
    class="calendar-day"
    :disabled="!!range && disabled"
  >
    <div ref="dayOfTheWeek" class="grey--text text--darken-1 text-overline">
      {{ $d(date, "dayOfTheWeek") }}
    </div>

    <v-avatar
      ref="dayOfTheMonth"
      v-if="range"
      style="margin-top: -8px; border-radius: 50%"
      color="white"
      class="grey--text text--darken-3 text-h5"
      size="46"
    >
      {{ $d(date, "dayOfTheMonth") }}
    </v-avatar>
  </v-card>
</template>

<style scoped>
.calendar-day {
  margin-top: 8px;
  height: 84px;
  text-align: center;
}
</style>

<script>
import moment from "moment";
export default {
  name: "Day",
  props: {
    range: { type: Object },
    date: { type: Date, required: true },
  },
  computed: {
    isToday() {
      return moment().isSame(this.date, "day");
    },
    disabled() {
      return !moment(this.date).isBetween(
        this.range.beg,
        this.range.end,
        "day",
        "[]"
      );
    },
  },
};
</script>
