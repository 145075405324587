<template>
  <v-sheet>
    <v-dialog
      v-model="dialog"
      max-width="485px"
      :close-on-content-click="false"
      hide-overlay
    >
      <EventForm
        autofocus
        :range="(applyToInstance && range) || null"
        :staff="staff"
        v-model="classEvent"
        :date.sync="classDate"
        @save="save"
        @close="close"
      />
    </v-dialog>

    <v-menu
      v-if="withInstanceAdd && range"
      y-offset
      v-model="menu"
      :positionX="menuX"
      :positionY="menuY"
    >
      <v-list dense>
        <v-list-item-group v-model="applyToInstance">
          <v-list-item :data-draft-add="0" :value="true" @click="dialog = true">
            <v-list-item-content>
              <v-list-item-title v-text="$t('one-time')" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :data-draft-add="1"
            :value="false"
            @click="dialog = true"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t('recurring')" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-fab-transition>
      <v-btn
        data-draft="add"
        color="grey darken-1"
        fab
        dark
        small
        absolute
        bottom
        left
        @click="popup"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-sheet>
</template>

<script>
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import EventForm from "./EventForm";
export default {
  name: "AddEventButton",
  components: {
    EventForm,
  },
  props: {
    withInstanceAdd: { type: Boolean },
    staff: { type: Array },
  },
  data: () => ({
    dialog: false,
    menu: false,
    menuX: 0,
    menuY: 0,
    applyToInstance: false,
    classEvent: undefined,
    classDate: null,
  }),
  computed: {
    ...mapGetters({
      range: "draft/range",
    }),
  },
  methods: {
    popup(ev) {
      if (this.withInstanceAdd && this.range) {
        const bbox = ev.target.getBoundingClientRect();
        this.menuX = bbox.x + bbox.width;
        this.menuY = bbox.y + bbox.height;
        this.menu = true;
      } else {
        this.dialog = true;
      }
    },
    save() {
      if (this.applyToInstance) {
        this.$emit("setInstance", {
          templateId: nanoid(12),
          date: this.classDate,
          payload: this.classEvent,
          isInstance: true,
        });
      } else {
        this.$emit("insertSeries", this.classEvent);
      }
      this.close();
    },
    close() {
      this.classEvent = undefined;
      this.classDate = null;
      this.dialog = false;
    },
  },
};
</script>
