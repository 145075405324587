<template>
  <div
    tabindex="-1"
    data-draft="timeCard"
    :data-draft-event-title="value.title"
    :data-draft-event-day="value.when.day"
    :data-draft-event-time="value.when.time"
    :data-draft-event-duration="value.when.duration"
    :data-draft-event-isInPerson="!!value.isInPerson"
    :data-draft-event-isVirtual="!!value.isVirtual"
    :data-draft-event-instructor="value.instructor"
    :class="`draft-card rounded-lg white--text ${size} ${
      isDragging ? 'drag' : ''
    }`"
    :style="`
      height: ${height}px;
      width: ${width}px;
      left: ${left}px;
      top: ${top}px;
    `"
    @keydown.delete="$emit('delete')"
    @keydown.esc="$emit('reset')"
    @keydown.enter="$emit('save')"
    @keydown.stop="onKeydown"
    @contextmenu.prevent="$emit('contextmenu', $event)"
  >
    <div id="activate" class="d-flex flex-column">
      <div
        class="flex-grow-1"
        id="drag"
        @mousedown.left="$emit('move', $event)"
      />
      <div id="resize" @mousedown.left="$emit('resize', $event)" />
    </div>

    <div id="content">
      <div id="title" class="text-caption font-weight-medium">
        {{ title ? title : $t("no title") }}
      </div>

      <div class="d-flex flex-wrap text-caption font-weight-regular">
        <div id="times">
          {{ times }}
        </div>
        <div>
          <v-icon dark small id="isInPerson" v-if="isInPerson"
            >mdi-human-male-female</v-icon
          >
          <v-icon dark small id="isVirtual" v-if="isVirtual"
            >mdi-access-point</v-icon
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#activate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#resize {
  height: 8px;
  cursor: ns-resize;
}

.draft-card {
  position: absolute;
  overflow: hidden;
  background-color: #757575;
  border: 1px solid white;
  padding: 8px 4px;
  min-height: 11px;
}

.draft-card.drag {
  opacity: 50%;
}

.draft-card:focus {
  outline: none;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

#content * {
  line-height: normal;
  user-select: none;
  white-space: nowrap;
}

.draft-card.x-small {
  padding-top: 0;
  padding-bottom: 0;
}

.x-small #content * {
  font-size: 11px !important;
}

.x-small #content {
  display: flex;
  white-space: nowrap;
  margin-top: -1px;
}

.x-small #title:after {
  content: ",";
  padding-right: 4px;
}

.small #content {
  display: flex;
  white-space: nowrap;
  margin-top: -4px;
}

.small #title:after {
  content: ",";
  padding-right: 4px;
}

.medium #content {
  margin-top: -4px;
}
</style>

<script>
import moment from "moment";

export default {
  name: "TimeEventCard",
  props: {
    isDragging: { type: Boolean, default: false },
    top: { type: Number, required: true },
    left: { type: Number, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    value: { type: Object, required: true },
  },
  computed: {
    title() {
      return this.value.title;
    },
    isInPerson() {
      return this.value.isInPerson;
    },
    isVirtual() {
      return this.value.isVirtual;
    },
    startTime() {
      return moment().startOf("day").add(this.value.when.time, "minutes");
    },
    endTime() {
      return this.startTime.clone().add(this.value.when.duration, "minutes");
    },
    size() {
      if (this.height <= 12) {
        return "x-small";
      }
      if (this.height <= 24) {
        return "small";
      }
      if (this.height <= 36) {
        return "medium";
      }
      return "regular";
    },
    times() {
      let startFormat = "h";
      if (this.startTime.minutes()) {
        startFormat += ":mm";
      }
      if (this.height <= 24) {
        startFormat += "a";
        return this.startTime.format(startFormat);
      }

      if (this.startTime.hours() < 12 != this.endTime.hours() < 12) {
        startFormat += "a";
      }
      let endFormat = "h";
      if (this.endTime.minutes()) {
        endFormat += ":mma";
      } else {
        endFormat += "a";
      }
      return this.$t("fromTo", {
        from: this.startTime.format(startFormat),
        to: this.endTime.format(endFormat),
      });
    },
  },
  methods: {
    onKeydown(ev) {
      if (ev.key.length === 1) this.$emit("keydown", ev);
    },
  },
};
</script>
