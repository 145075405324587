var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"flat":"","dense":"","fixed":""}},[(_vm.withRange && _vm.range)?[_c('WeekPicker',{staticClass:"mx-auto",attrs:{"week":_vm.week},on:{"change":function (v) { return _vm.$emit('update:week', v); }}}),_c('v-switch',{staticClass:"px-4",staticStyle:{"position":"absolute","right":"0"},attrs:{"id":"show-cancelled","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption font-weight-medium",domProps:{"textContent":_vm._s(_vm.$t('show cancelled'))}})]},proxy:true}],null,false,2416363318),model:{value:(_vm.showCancelled),callback:function ($$v) {_vm.showCancelled=$$v},expression:"showCancelled"}})]:_vm._e(),_c('v-progress-linear',{attrs:{"absolute":"","indeterminate":"","bottom":"","active":!_vm.ready}}),(_vm.withSeriesAdd)?_c('AddEventButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}],attrs:{"with-instance-add":_vm.withRange && _vm.withInstanceAdd,"staff":_vm.staff},on:{"insertSeries":function (v) { return _vm.$emit('updateSeries', v); },"setInstance":function (v) { return _vm.$emit('setInstance', v); }}}):_vm._e()],2),_c('v-card',{ref:"tb",attrs:{"flat":"","tile":""}},[_c('v-tabs-items',{attrs:{"value":_vm.week},on:{"change":function (v) { return (v ? _vm.$emit('update:week', v) : undefined); }}},[_c('v-tab-item',{key:-1,attrs:{"value":-1}},[(_vm.template && !(_vm.withRange && _vm.range))?_vm._t("default",null,{"attrs":{ viewport: _vm.viewport, events: _vm.events, staff: _vm.staff },"on":{
            updateSeries: function (v, id) { return _vm.$emit('updateSeries', v, id); },
            deleteSeries: function (id) { return _vm.$emit('deleteSeries', id); },
          }}):_vm._e()],2),_vm._l((_vm.totalWeeks),function(_,w){return _c('v-tab-item',{key:w,attrs:{"value":w,"data-draft-week":w}},[(_vm.template && _vm.withRange && _vm.range && w in _vm.weeks)?_vm._t("default",null,{"attrs":{
            viewport: _vm.viewport,
            week: w,
            range: _vm.range,
            staff: _vm.staff,
            holidays: _vm.getHolidaysFor(w),
            events: _vm.getEventsFor(w),
          },"on":{
            setInstance: function (v) { return _vm.$emit('setInstance', v); },
            unsetInstance: function (v) { return _vm.$emit('unsetInstance', v); },
            updateSeries: function (v, id) { return _vm.$emit('updateSeries', v, id); },
            deleteSeries: function (id) { return _vm.$emit('deleteSeries', id); },
          }}):_vm._e()],2)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }