<template>
  <div class="agenda" :style="`min-width: ${width}px`">
    <div
      v-for="(a, i) in agenda"
      :key="i"
      :data-draft-agenda="a"
      class="primary white--text text-caption font-weight-bold rounded px-2"
    >
      {{ a }}
    </div>
  </div>
</template>

<style scoped>
.agenda {
  min-height: 24px;
  padding-right: 16px;
  border-left: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
}

.agenda * {
  margin-bottom: 3px;
}
</style>

<style>
.no-agenda {
  position: relative;
  bottom: 24px;
  margin-bottom: -24px;
}
</style>

<script>
export default {
  name: "Agenda",
  props: {
    agenda: { type: Array, default: () => [] },
    width: { type: Number },
  },
};
</script>
