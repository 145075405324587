<template>
  <v-row>
    <v-col>
      <v-menu
        v-if="range"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="248px"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            id="date"
            ref="date"
            v-bind="attrs"
            v-on="on"
            required
            readonly
            :value="getPrettyDate()"
            :rules="getDateRules()"
            dense
            hide-details="auto"
          />
        </template>
        <v-date-picker
          ref="datePicker"
          width="248"
          first-day-of-week="1"
          flat
          no-title
          :min="getMinDate()"
          :max="getMaxDate()"
          :value="getDate()"
          @input="setDate"
        />
      </v-menu>
      <v-autocomplete
        id="day"
        ref="day"
        v-else
        dense
        auto-select-first
        hide-details="auto"
        required
        :append-icon="null"
        :items="days"
        :rules="getDayRules()"
        :value="day"
        @input="setDay"
      />
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        id="startTime"
        ref="startTime"
        :append-icon="null"
        dense
        auto-select-first
        no-filter
        hide-details="auto"
        required
        :value="time"
        @input="setStartTime"
        :items="getStartTimes()"
        :search-input.sync="startTimeSearchInput"
        :rules="getStartTimeRules()"
        :menu-props="{ minWidth: '100px' }"
      />
    </v-col>

    <v-col cols="3">
      <v-autocomplete
        id="endTime"
        ref="endTime"
        :append-icon="null"
        dense
        auto-select-first
        no-filter
        hide-details="auto"
        required
        :disabled="isNaN(startTime)"
        :value="endTime"
        @input="setEndTime"
        :items="getEndTimes()"
        :search-input.sync="endTimeSearchInput"
        :rules="getEndTimeRules()"
        :menu-props="{ minWidth: '100px' }"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

const TESTS = [
  "H",
  "HH",
  "h",
  "hh",
  "Hmm",
  "HHmm",
  "hmm",
  "hhmm",
  "Ha",
  "HHa",
  "ha",
  "hha",
  "Hmma",
  "HHmma",
  "hmma",
  "hhmma",
  "H:",
  "HH:",
  "h:",
  "hh:",
  "H:mm",
  "HH:mm",
  "h:mm",
  "hh:mm",
  "H:a",
  "HH:a",
  "h:a",
  "hh:a",
  "H:mma",
  "HH:mma",
  "h:mma",
  "hh:mma",
];

export default {
  name: "DayTimePicker",
  props: {
    range: { type: Object },
    date: { type: Date },
    day: { type: Number },
    time: { type: Number },
    duration: { type: Number, required: true },
  },
  data: () => ({
    menu: false,
    startTimeSearchInput: null,
    endTimeSearchInput: null,
    days: Array.from({ length: 7 }, (_, i) => ({
      text: moment()
        .weekday(i + 1)
        .format("dddd"),
      value: i + 1,
    })),
  }),
  computed: {
    startTime() {
      return parseInt(this.time);
    },
    endTime() {
      return !isNaN(this.startTime) ? this.time + this.duration : undefined;
    },
  },
  methods: {
    getPrettyDate() {
      return this.date ? moment(this.date).format("dddd, MMM D") : null;
    },
    getDate() {
      return this.date ? moment(this.date).format("YYYY-MM-DD") : null;
    },
    setDate(value) {
      const d = moment(value);
      this.$emit("update:date", d.toDate(), d.isoWeekday());
      this.menu = false;
    },
    getDateRules() {
      return [
        () => !!this.date,
        () => !!this.day,
        () =>
          moment(this.date).isBetween(
            this.range?.beg,
            this.range?.end,
            "day",
            []
          ) || this.$t("invalid date"),
      ];
    },
    getMinDate() {
      return moment(this.range.beg).format("YYYY-MM-DD");
    },
    getMaxDate() {
      return moment(this.range.end).format("YYYY-MM-DD");
    },
    setDay(value) {
      this.$emit("update:day", value);
    },
    getDayRules() {
      return [
        (value) => !isNaN(parseInt(value)),
        (value) => (value >= 1 && value <= 7) || this.$t("invalid day"),
      ];
    },
    getStartTimes() {
      return this.getTimes(this.startTimeSearchInput);
    },
    setStartTime(value) {
      this.$emit(
        "update:time",
        value,
        Math.min(this.duration, 24 * 60 - value)
      );
    },
    getStartTimeRules() {
      return [
        (value) => !isNaN(parseInt(value)),
        (value) => value >= 0 && value < 24 * 60 && value < this.endTime,
        () => !!this.getStartTimes().length || this.$t("invalid time"),
      ];
    },
    getEndTimes() {
      return this.getTimes(this.endTimeSearchInput, this.time);
    },
    setEndTime(value) {
      if (isNaN(this.startTime)) return;
      this.$emit("update:duration", value - this.time);
    },
    getEndTimeRules() {
      return [
        (value) => !isNaN(parseInt(value)),
        (value) => value > 0 && value <= 24 * 60 && value > this.time,
        () => !!this.getEndTimes().length || this.$t("invalid time"),
      ];
    },
    getTimes(query, from = -15) {
      const times = Array.from({ length: 24 * 4 }, (_, i) => ({
        text: moment()
          .startOf("day")
          .add(i * 15 + from, "minute")
          .format("h:mma"),
        value: (i * 15 + from) % (48 * 60),
      }));
      if (!query) {
        return times.filter((t) => t.value > from && t.value <= 24 * 60);
      }
      // strip spaces and colons
      const normalizedQuery = query.split(/\s+/).join("");
      const time = moment(normalizedQuery, TESTS);
      if (!time.isValid()) {
        return [];
      }
      const minutes = time.diff(time.clone().startOf("day"), "minutes");
      if (!(minutes % 15)) {
        return times
          .filter(
            (t) => t.value >= minutes && t.value > from && t.value <= 24 * 60
          )
          .sort((a, b) => a.value - b.value);
      }
      return [
        {
          text: time.format("h:mma"),
          value: minutes,
        },
      ];
    },
  },
};
</script>
