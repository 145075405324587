<template>
  <GridYAxis
    :align-bottom="alignBottom"
    :labels="labels"
    :box-height="boxHeight"
  />
</template>

<script>
import moment from "moment";
import GridYAxis from "./GridYAxis";

export default {
  name: "Times",
  components: {
    GridYAxis,
  },
  props: {
    alignBottom: { type: Boolean },
    times: { type: Array, required: true },
    boxHeight: { type: Number, required: true },
    format: { type: String, required: true },
  },
  computed: {
    labels() {
      return this.times.map((t) =>
        moment().startOf("day").add(t, "minutes").format(this.format)
      );
    },
  },
};
</script>
