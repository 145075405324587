<template>
  <div class="d-flex flex-row align-center">
    <v-btn
      ref="prev"
      id="prevPage"
      icon
      small
      @click="$emit('change', week - 1)"
      :disabled="week <= 0"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-btn
      ref="next"
      id="nextPage"
      icon
      small
      @click="$emit('change', week + 1)"
      :disabled="week >= totalWeeks - 1"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="248px"
      offset-y
      nudge-left="56"
    >
      <template #activator="{ on, attrs }">
        <v-card
          ref="selector"
          flat
          tile
          min-width="160"
          class="text-h5"
          v-bind="attrs"
          v-on="on"
        >
          <span>{{ label }}</span>
          <v-icon small>mdi-chevron-down</v-icon>
        </v-card>
      </template>
      <v-date-picker
        width="248"
        color="accent"
        first-day-of-week="1"
        flat
        no-title
        :min="minDate"
        :max="maxDate"
        :value="date"
        @input="(value) => setWeek(getWeekFromDate(value))"
      />
    </v-menu>
  </div>
</template>

<style scoped>
#current-week {
  color: #616161 !important;
  font-family: "Cantanta", serif !important;
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
const DATE_FORMAT = "YYYY-MM-DD";
export default {
  name: "WeekPicker",
  model: {
    prop: "week",
    event: "change",
  },
  props: {
    week: { type: Number, required: true },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapGetters({
      range: "draft/range",
      totalWeeks: "draft/totalWeeks",
    }),
    date() {
      return moment
        .max(
          moment(this.range.beg),
          moment(this.range.beg).startOf("isoWeek").add(this.week, "weeks")
        )
        .format(DATE_FORMAT);
    },
    label() {
      const mon = moment(this.range.beg)
        .startOf("isoWeek")
        .add(this.week, "weeks");
      const sun = mon.clone().endOf("isoWeek");
      if (mon.year() !== sun.year()) {
        const from = mon.format("MMM YYYY");
        const to = sun.format("MMM YYYY");
        return `${from}-${to}`;
      }
      if (mon.month() !== sun.month()) {
        const from = mon.format("MMM");
        const to = sun.format("MMM YYYY");
        return `${from}-${to}`;
      }
      return mon.format("MMMM YYYY");
    },
    minDate() {
      return moment(this.range.beg).format(DATE_FORMAT);
    },
    maxDate() {
      return moment(this.range.end).format(DATE_FORMAT);
    },
  },
  methods: {
    getWeekFromDate(value) {
      const beg = moment(this.range.beg).startOf("isoWeek");
      return moment(value).diff(beg, "weeks");
    },
    setWeek(week) {
      if (week !== this.week) this.$emit("change", week);
      this.menu = false;
    },
  },
};
</script>
