<template>
  <div
    :class="[
      'd-flex',
      'flex-column',
      'align-stretch',
      alignBottom ? 'align-bottom' : '',
    ]"
  >
    <GridMargin
      v-for="(label, i) in labels"
      :key="i"
      :height="boxHeight"
      :data-y-index="i"
    >
      <span class="text-caption grey--text text--darken-1 pl-2">
        {{ label }}
      </span>
    </GridMargin>
  </div>
</template>

<style scoped>
.align-bottom span {
  position: relative;
  top: 50%;
  z-index: 1;
}
</style>

<script>
import GridMargin from "./GridMargin";

export default {
  name: "GridYAxis",
  components: {
    GridMargin,
  },
  props: {
    alignBottom: { type: Boolean, default: false },
    labels: { type: Array },
    boxHeight: { type: Number },
  },
};
</script>
