<template>
  <div>
    <canvas ref="canvas" :data-automatic="canvas && redraw" />
  </div>
</template>

<script>
export default {
  name: "Grid",
  props: {
    alignBottom: { type: Boolean },
    boxWidth: { type: Number },
    boxHeight: { type: Number },
    rows: { type: Number },
    columns: { type: Number },
  },
  data: () => ({
    canvas: null,
  }),
  computed: {
    width() {
      return this.boxWidth * this.columns;
    },
    height() {
      return (
        this.boxHeight * this.rows + (this.alignBottom ? this.boxHeight : 0)
      );
    },
    redraw() {
      this.draw();
      return true;
    },
  },
  methods: {
    draw() {
      this.canvas.width = this.width + 1;
      this.canvas.height = this.height + 1;

      const ctx = this.canvas.getContext("2d");
      ctx.clearRect(0, 0, this.width + 1, this.height + 1);

      ctx.beginPath();
      ctx.strokeStyle = "#bdbdbd";
      ctx.lineWidth = 1;

      for (let i = 0; i < this.columns + 1; i++) {
        ctx.moveTo(i * this.boxWidth + 0.5, 0);
        ctx.lineTo(i * this.boxWidth + 0.5, this.height);
      }
      for (let i = 1; i <= this.rows; i++) {
        ctx.moveTo(0, i * this.boxHeight - 0.5);
        ctx.lineTo(this.width, i * this.boxHeight - 0.5);
      }
      ctx.stroke();
    },
  },
  mounted() {
    this.canvas = this.$refs.canvas;
  },
};
</script>
