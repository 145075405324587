<template>
  <div class="d-flex flex-row flex-wrap py-4 text-caption">
    <div>
      <a :href="termsOfService" target="_blank" id="terms">{{
        $t("terms.short")
      }}</a>
    </div>
    <div class="px-2">·</div>
    <div>
      <a :href="privacyPolicy" target="_blank" id="privacy">{{
        $t("privacy.short")
      }}</a>
    </div>
    <div class="px-2">·</div>
    <div>
      <a :href="disclaimer" target="_blank" id="disclaimer">{{
        $t("disclaimer.short")
      }}</a>
    </div>
    <div class="px-2">·</div>
    <div>
      <a
        class="termly-cookie-preference-button"
        onclick="displayPreferenceModal()"
        id="cookiePrefs"
      >
        {{ $t("manage cookie preferences") }}
      </a>
    </div>
    <template v-if="isLoggedIn">
      <div class="px-2">·</div>
      <div>
        <a :href="doNotSellMyInfo" target="_blank" id="doNotSell">
          {{ $t("do not sell my info") }}
        </a>
      </div>
    </template>
  </div>
</template>

<style scoped>
a:link,
a:visited,
a:hover,
a:active {
  color: #757575;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      termsOfService: "config/termsOfService",
      privacyPolicy: "config/privacyPolicy",
      disclaimer: "config/disclaimer",
      doNotSellMyInfo: "config/doNotSellMyInfo",
    }),
  },
};
</script>
