<template>
  <v-card flat tile>
    <v-toolbar flat dense fixed style="z-index: 2">
      <template v-if="withRange && range">
        <WeekPicker
          class="mx-auto"
          :week="week"
          @change="(v) => $emit('update:week', v)"
        />

        <v-switch
          id="show-cancelled"
          class="px-4"
          style="position: absolute; right: 0"
          dense
          hide-details
          v-model="showCancelled"
        >
          <template #label>
            <span
              class="text-caption font-weight-medium"
              v-text="$t('show cancelled')"
            />
          </template>
        </v-switch>
      </template>

      <v-progress-linear absolute indeterminate bottom :active="!ready" />

      <AddEventButton
        v-if="withSeriesAdd"
        v-show="ready"
        :with-instance-add="withRange && withInstanceAdd"
        :staff="staff"
        @insertSeries="(v) => $emit('updateSeries', v)"
        @setInstance="(v) => $emit('setInstance', v)"
      />
    </v-toolbar>

    <v-card ref="tb" flat tile>
      <v-tabs-items
        :value="week"
        @change="(v) => (v ? $emit('update:week', v) : undefined)"
      >
        <v-tab-item :key="-1" :value="-1">
          <slot
            v-if="template && !(withRange && range)"
            :attrs="{ viewport, events, staff }"
            :on="{
              updateSeries: (v, id) => $emit('updateSeries', v, id),
              deleteSeries: (id) => $emit('deleteSeries', id),
            }"
          />
        </v-tab-item>
        <v-tab-item
          v-for="(_, w) in totalWeeks"
          :key="w"
          :value="w"
          :data-draft-week="w"
        >
          <slot
            v-if="template && withRange && range && w in weeks"
            :attrs="{
              viewport,
              week: w,
              range,
              staff,
              holidays: getHolidaysFor(w),
              events: getEventsFor(w),
            }"
            :on="{
              setInstance: (v) => $emit('setInstance', v),
              unsetInstance: (v) => $emit('unsetInstance', v),
              updateSeries: (v, id) => $emit('updateSeries', v, id),
              deleteSeries: (id) => $emit('deleteSeries', id),
            }"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import AddEventButton from "./AddEventButton";
import WeekPicker from "./WeekPicker";
export default {
  name: "EventsLayout",
  components: {
    AddEventButton,
    WeekPicker,
  },
  props: {
    week: { type: Number, default: -1 },
    withRange: { type: Boolean },
    withSeriesAdd: { type: Boolean },
    withInstanceAdd: { type: Boolean },
  },
  data: () => ({
    showCancelled: false,
    viewport: {
      clientWidth: 0,
      clientHeight: 0,
    },
  }),
  computed: {
    ...mapGetters({
      template: "draft/template",
      holidays: "draft/holidays",
      weeks: "draft/weeks",
      range: "draft/range",
      totalWeeks: "draft/totalWeeks",
    }),
    ready() {
      return (
        this.template &&
        (!(this.withRange && this.range) || this.week in this.weeks)
      );
    },
    events() {
      return Object.entries(this.template).map(([templateId, payload]) => ({
        templateId,
        payload,
      }));
    },
    staff() {
      const staff = Object.values(this.template ?? {})
        .filter((ev) => ev.instructor)
        .map((ev) => ev.instructor);
      Object.values(this.weeks).forEach((week) =>
        Object.values(week)
          .filter((oc) => oc.payload?.instructor)
          .forEach((oc) => staff.push(oc.payload.instructor))
      );
      return staff.filter((name, i, self) => self.indexOf(name) === i).sort();
    },
  },
  watch: {
    $route: {
      handler() {
        this.onWindowResize();
      },
      immediate: true,
    },
  },
  methods: {
    async onWindowResize() {
      await this.$nextTick();
      const tb = this.$refs.tb.$el.getBoundingClientRect();
      this.viewport = {
        clientWidth: window.innerWidth - tb.left,
        clientHeight: window.innerHeight - tb.top,
      };
    },
    getEventsFor(week) {
      const weekStart = moment(this.range.beg)
        .startOf("isoWeek")
        .add(week, "weeks");
      const beg = moment.max(weekStart, moment(this.range.beg));
      const end = moment.min(
        weekStart.clone().endOf("isoWeek"),
        moment(this.range.end)
      );
      const events = [];
      const occurs = Object.values(this.weeks[week]);
      this.events.forEach((ev) => {
        // filter events that are out of range
        const day = ev.payload.when.day;
        if (day < beg.isoWeekday() || day > end.isoWeekday()) return;

        // find the occurrence or push the event as-is
        const index = occurs.findIndex((oc) => oc.templateId === ev.templateId);
        if (index < 0) {
          events.push(ev);
          return;
        }
        const [oc] = occurs.splice(index, 1);
        events.push({
          ...ev,
          ...oc,
          payload: {
            ...ev.payload,
            ...oc.payload,
          },
          template: ev.payload,
        });
      });

      Object.values(occurs)
        .filter((oc) => oc.isInstance)
        .forEach((oc) => {
          // filter events that are out of range
          if (moment(oc.date).isBetween(beg, end, "day", [])) events.push(oc);
        });
      return !this.showCancelled
        ? events.filter((ev) => !ev.isCancelled)
        : events;
    },
    getHolidaysFor(week) {
      const w = [[], [], [], [], [], [], []];
      const weekStart = moment(this.range.beg)
        .startOf("isoWeek")
        .add(week, "weeks");
      const beg = moment.max(weekStart, moment(this.range.beg)).toDate();
      const end = moment
        .min(weekStart.endOf("isoWeek"), moment(this.range.end))
        .toDate();
      this.holidays.forEach((ev) => {
        const date = moment(ev.date);
        if (date.isBetween(beg, end, "day", "[]")) {
          w[date.isoWeekday() - 1].push(ev.title);
        }
      });
      return w;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
};
</script>
