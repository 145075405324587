<template>
  <div style="position: relative">
    <!-- grid -->
    <div
      ref="canvas"
      @mousedown.left.prevent="onEventDrag($event, onEventResize, null, 0, 1)"
      @contextmenu.prevent="onEventDrag($event, onEventResize, null, 0, 1)"
    >
      <Grid
        align-bottom
        :box-width="boxWidth"
        :box-height="boxHeight"
        :rows="rows"
        :columns="columns"
      />
    </div>

    <!-- events -->
    <TimeEventCard
      ref="events"
      v-for="(event, i) in state"
      :key="event.templateId"
      :data-draft-event-id="event.templateId"
      v-bind="positionAt(i)"
      :value="event.payload"
      :is-dragging="isDragging(i)"
      @move.prevent="onEventDrag($event, onEventMove, i, 2, 1)"
      @resize.prevent="onEventDrag($event, onEventResize, i, 0, 1)"
      @contextmenu.prevent="onEventContextMenu($event, i)"
      @delete="onEventDelete(event)"
      @reset="onEventReset()"
      @save="onEventSave()"
      @keydown="onFormFocus"
    />

    <!-- draggable event -->
    <v-fade-transition>
      <TimeEventCard
        ref="drag"
        data-draft="drag"
        class="select-none"
        v-bind="drag.position"
        :value="drag.curr"
        v-if="drag.show"
      />
    </v-fade-transition>

    <!-- event form -->
    <v-menu
      v-model="form.show"
      :position-x="form.x"
      :position-y="form.y"
      :close-on-click="false"
      :close-on-content-click="false"
      absolute
      offset-y
    >
      <EventForm
        ref="form"
        v-model="form.curr"
        :event-id="
          !form.isNew && form.index !== null
            ? state[form.index].templateId
            : null
        "
        :staff="staff"
        @close="onEventReset()"
        @save="onEventSave()"
        @copy="onEventCopy(events[form.index])"
        @delete="onEventDelete(state[form.index])"
        :style="`max-width: ${formWidth}px; max-height: ${formHeight}px`"
      />
    </v-menu>

    <!-- event context -->
    <v-menu
      v-model="context.show"
      :position-x="context.x"
      :position-y="context.y"
      absolute
      offset-y
    >
      <v-list ref="contextmenu">
        <v-list-item
          id="copySeries"
          @click="onEventCopy(events[context.index])"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t('copy')" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          id="deleteSeries"
          @click="onEventDelete(events[context.index])"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t('delete')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Grid from "./Grid";
import TimeEventCard from "./TimeEventCard";
import EventForm from "./EventForm";
import _ from "lodash";
export default {
  name: "TimeEvents",
  components: {
    Grid,
    TimeEventCard,
    EventForm,
  },
  props: {
    clientWidth: { type: Number, required: true },
    boxWidth: { type: Number, required: true },
    boxHeight: { type: Number, required: true },
    rows: { type: Number, required: true },
    columns: { type: Number, required: true },
    events: { type: Array, required: true },
    staff: { type: Array, required: true },
  },
  data: () => ({
    defaultEvent: {
      when: { duration: 60 },
    },
    copyEvent: {
      when: { duration: 60 },
    },
    drag: {
      show: false,
      position: null,
      offset: null,
      curr: null,
    },
    form: {
      show: false,
      isNew: false,
      index: null,
      x: 0,
      y: 0,
      curr: { when: { duration: 60 } },
    },
    context: {
      show: false,
      index: null,
      x: 0,
      y: 0,
    },
    margin: 12,
    formWidth: 485,
    formHeight: 346,
    maxTime: 24 * 60,
  }),
  computed: {
    state() {
      const events = this.events.slice();
      const index = this.form.index;
      if (index !== null) {
        const ev = index < events.length ? _.cloneDeep(events[index]) : {};
        events.splice(index, 1, Object.assign(ev, { payload: this.form.curr }));
      }
      return events;
    },
  },
  methods: {
    getYScrollableParent() {
      function isScrollable(el) {
        const hasScrollableContent = el.scrollHeight > el.clientHeight;
        const overflowStyle = window.getComputedStyle(el).overflowY;
        const isOverflowHidden = overflowStyle.indexOf("hidden") !== -1;
        return hasScrollableContent && !isOverflowHidden;
      }
      function getScrollableParent(el) {
        return !el || el === document.body
          ? document.body
          : isScrollable(el)
          ? el
          : getScrollableParent(el.parentNode);
      }
      return getScrollableParent(this.$el);
    },
    getXScrollableParent() {
      function isScrollable(el) {
        const hasScrollableContent = el.scrollWidth > el.clientWidth;
        const overflowStyle = window.getComputedStyle(el).overflowX;
        const isOverflowHidden = overflowStyle.indexOf("hidden") !== -1;
        return hasScrollableContent && !isOverflowHidden;
      }
      function getScrollableParent(el) {
        return !el || el === document.body
          ? document.body
          : isScrollable(el)
          ? el
          : getScrollableParent(el.parentNode);
      }
      return getScrollableParent(this.$el);
    },
    isDragging(i) {
      return this.drag.show && i === this.form.index;
    },
    positionAt(i) {
      const ev = this.state[i].payload;
      const day = ev.when.day - 1;
      // count all of the intersections
      const start = day * this.maxTime + ev.when.time;
      const end = start + ev.when.duration;
      let xOffset = 0;
      let isects = 0;
      this.state
        .map((ev) => ev.payload)
        .forEach((ev, j) => {
          const otherStart = (ev.when.day - 1) * this.maxTime + ev.when.time;
          const otherEnd = otherStart + ev.when.duration;
          if (start < otherEnd && end > otherStart) {
            if (j < i) {
              xOffset++;
            }
            isects++;
          }
        });
      // calculate the position based on the number of intersections and the
      // position of the event within the array
      const OFFSET = 8;
      const width =
        (this.boxWidth - this.margin + (isects - 1) * OFFSET) / isects;
      const left = this.boxWidth * day + xOffset * (width - OFFSET);
      return Object.assign(this.getPositionFromTime(ev.when, 15), {
        left,
        width,
      });
    },
    getPositionFromTime(when, round = 1) {
      let d = Math.min(when.duration, this.maxTime - when.time);
      return {
        left: (when.day - 1) * this.boxWidth,
        top: ((Math.floor(when.time / round) * round) / 60) * this.boxHeight,
        height: ((Math.floor(d / round) * round) / 60) * this.boxHeight,
      };
    },
    getTimeFromPosition(ev, offset = 0, round = 1) {
      const bbox = this.$el.getBoundingClientRect();
      const x = ev.clientX - bbox.left;
      const y = ev.clientY - bbox.top;
      // get the day of the week
      const day = Math.max(Math.min(Math.floor(x / this.boxWidth) + 1, 7), 1);
      // get the time (round to the rounding value)
      const time = Math.max(
        0,
        Math.floor(((y / this.boxHeight) * 60) / round) * round +
          (offset % round)
      );
      return { day, time };
    },
    async updateFormPosition() {
      await this.$nextTick();
      const selectEvent = this.$refs.events[this.form.index];
      selectEvent.$el.focus();

      // get the positions
      const gridBox = this.$el.getBoundingClientRect();
      const cardBox = selectEvent.$el.getBoundingClientRect();

      // calculate the x position
      const maxWidth = Math.min(this.clientWidth, gridBox.left + gridBox.width);
      const leftSpacing = cardBox.left - gridBox.left;
      const rightSpacing = maxWidth - leftSpacing - cardBox.width - this.margin;

      if (this.formWidth < leftSpacing) {
        this.form.x = cardBox.left - this.formWidth;
      } else if (this.formWidth < rightSpacing) {
        this.form.x = cardBox.left + cardBox.width + this.margin;
      } else {
        this.form.x = (this.clientWidth - this.formWidth) / 2;
      }

      // calculate the y position
      const gridTop = gridBox.top + this.getYScrollableParent().scrollTop;
      const topSpacing = cardBox.top - gridTop;

      if (topSpacing > this.formHeight) {
        this.form.y = cardBox.top - this.formHeight;
      } else {
        this.form.y = gridTop;
      }

      this.form.show = true;
    },
    onDrag(ev, handleMove, handleEnd, xScroll, yScroll) {
      const xEl = this.getXScrollableParent();
      const startX = ev.pageX - xEl.offsetLeft;
      const scrollLeft = xEl.scrollLeft;
      const yEl = this.getYScrollableParent();
      const startY = ev.pageY - yEl.offsetTop;
      const scrollTop = yEl.scrollTop;
      const root = this.$el;
      const debounce = Date.now();
      function onMove(ev) {
        ev.preventDefault();
        const x = ev.pageX - xEl.offsetLeft;
        const walkX = (x - startX) * xScroll;
        xEl.scrollLeft = scrollLeft + walkX;
        const y = ev.pageY - yEl.offsetTop;
        const walkY = (y - startY) * yScroll;
        yEl.scrollTop = scrollTop + walkY;
        if (Date.now() - debounce > 100) {
          handleMove(ev);
        }
      }
      root.addEventListener("mousemove", onMove);
      function onUp(ev) {
        ev.preventDefault();
        root.removeEventListener("mousemove", onMove);
        window.removeEventListener("mouseup", onUp);
        handleEnd(ev);
      }
      window.addEventListener("mouseup", onUp);
    },
    onEventContextMenu(ev, index) {
      if (this.form.show) {
        return;
      }
      this.context.show = true;
      this.context.index = index;
      this.context.x = ev.clientX;
      this.context.y = ev.clientY;
    },
    onEventDrag(ev, handle, index, xScroll, yScroll) {
      if (this.context.show) {
        return;
      }

      // deselect the current event
      if (this.form.index !== null && this.form.index !== index) {
        this.onEventReset();
        return;
      }

      // clone the event state
      if (this.form.index === null) {
        if (index !== null) {
          this.form.curr = _.cloneDeep(this.events[index].payload);
        } else if (ev.button === 0) {
          const curr = _.cloneDeep(this.defaultEvent);
          Object.assign(curr.when, this.getTimeFromPosition(ev, 0, 30));
          this.form.curr = curr;
        } else {
          const curr = _.cloneDeep(this.copyEvent);
          Object.assign(curr.when, this.getTimeFromPosition(ev, 0, 30));
          this.form.curr = curr;
        }
        this.form.isNew = index === null;
        this.form.index = index;
      }

      // calculate the drag shift based on the current mouse position
      const { day, time, duration } = this.form.curr.when;
      const { time: offset } = this.getTimeFromPosition(ev, time, 15);
      this.drag.curr = _.cloneDeep(this.form.curr);
      this.drag.offset = {
        day,
        time,
        duration,
        offsetStartTime: offset - time,
        offsetEndTime: offset - time - duration,
      };
      this.drag.position = { left: 0, top: 0, width: this.boxWidth, height: 0 };
      this.onDrag(ev, handle, this.onEventDragEnd, xScroll, yScroll);
    },
    async onEventDragEnd(ev) {
      if (ev.button > 0 && this.form.isNew) {
        const payload = this.drag.curr;
        const index = this.events.length;
        this.$emit("insertSeries", payload);
        this.onEventReset();
        await this.$nextTick();
        if (this.events.length > index) this.$refs.events[index].$el.focus();
      } else if (this.form.show || !this.drag.show || this.form.isNew) {
        this.form.curr = this.drag.curr;
        this.form.index ??= this.events.length;
        this.updateFormPosition();
      } else {
        const payload = this.drag.curr;
        const index = this.form.index;
        this.$emit("updateSeries", this.events[index].templateId, payload);
        this.onEventReset();
        await this.$nextTick();
        this.$refs.events[index]?.$el.focus();
      }

      this.drag.show = false;
      this.drag.offset = null;
      this.drag.position = null;
      this.drag.curr = null;
    },
    onEventMove(ev) {
      this.drag.show = true;
      this.form.show = false;
      const when = this.drag.curr.when;
      const begn = this.drag.offset;
      const curr = this.getTimeFromPosition(ev, begn.time, 15);
      curr.time = Math.max(curr.time - begn.offsetStartTime, 0);
      curr.time = Math.min(curr.time, this.maxTime - begn.duration);
      Object.assign(when, curr);
      Object.assign(this.drag.position, this.getPositionFromTime(when), 15);
    },
    onEventResize(ev) {
      this.drag.show = true;
      this.form.show &&= this.form.isNew;
      const when = this.drag.curr.when;
      const begn = this.drag.offset;
      const curr = this.getTimeFromPosition(ev, begn.time, 15);
      when.day = begn.day;
      when.time =
        curr.time === begn.time
          ? curr.time - 15
          : Math.min(begn.time, curr.time);
      when.duration = Math.max(Math.abs(curr.time - begn.time), 15);
      when.duration = Math.min(when.duration, this.maxTime - when.time);
      Object.assign(this.drag.position, this.getPositionFromTime(when), 15);
    },
    onEventCopy(data) {
      this.copyEvent = _.cloneDeep(data.payload);
      this.onEventReset();
      this.$store.dispatch("alert/info", this.$t("class copied"));
    },
    onEventSave() {
      const payload = this.form.curr;
      if (this.form.isNew) {
        this.$emit("insertSeries", payload);
      } else {
        this.$emit(
          "updateSeries",
          this.events[this.form.index].templateId,
          payload
        );
      }
      this.onEventReset();
    },
    onEventDelete(data) {
      if (data.templateId) this.$emit("deleteSeries", data.templateId);
      this.onEventReset();
    },
    onEventReset() {
      this.form.show = false;
      if (this.form.index < this.events.length)
        this.$refs.events[this.form.index]?.$el.blur();
      this.form.index = null;
      this.form.isNew = false;
      this.form.x = 0;
      this.form.y = 0;
      this.form.curr = { when: { duration: 60 } };
    },
    onFormFocus() {
      if (!this.form.show) return;
      const el = this.$refs.form.$el.querySelector("#title");
      el.focus();
      el.select();
    },
  },
  watch: {
    clientWidth() {
      if (this.form.show) {
        this.updateFormPosition();
      }
    },
  },
};
</script>
