<template>
  <v-autocomplete
    :append-icon="null"
    :search-input.sync="searchInput"
    auto-select-first
    clearable
    dense
    hide-no-data
    :disabled="disabled"
    :autofocus="autofocus"
    :label="label"
    :value="value"
    :items="normalizedItems.concat(searchInput ? [searchInput] : [])"
    :placeholder="placeholder"
    @input="(v) => $emit('input', v)"
  >
    <template #item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <template v-if="items.includes(item)">
          <v-list-item-avatar
            :size="24"
            color="white"
            v-html="avatars.create(item)"
          />
          <v-list-item-content>
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-jdenticon-sprites";
export default {
  name: "StaffAutocomplete",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    label: { type: String },
    placeholder: { type: String },
    value: { type: String },
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    searchInput: null,
    avatars: new Avatars(sprites, {}),
  }),
  computed: {
    normalizedItems() {
      return this.searchInput
        ? this.items.filter((n) =>
            n
              .toLocaleLowerCase()
              .startsWith(this.searchInput.toLocaleLowerCase())
          )
        : this.items;
    },
  },
};
</script>
