<template>
  <EventsLayout
    with-series-add
    @updateSeries="(v, id) => $emit('updateSeries', v, id)"
    @deleteSeries="(id) => $emit('deleteSeries', id)"
  >
    <template #default="{ attrs, on }">
      <TimeEventsView
        v-bind="attrs"
        v-on="on"
        :min-box-width="minBoxWidth"
        :min-box-height="minBoxHeight"
        :max-box-width="maxBoxWidth"
        :max-box-height="maxBoxHeight"
      >
        <template #footer>
          <Footer />
        </template>
      </TimeEventsView>
    </template>
  </EventsLayout>
</template>

<script>
import EventsLayout from "@/components/draft/EventsLayout";
import TimeEventsView from "@/components/draft/TimeEventsView";
import Footer from "@/components/home/Footer";
export default {
  name: "TimesDraft",
  components: {
    EventsLayout,
    TimeEventsView,
    Footer,
  },
  props: {
    minBoxWidth: { type: Number },
    maxBoxWidth: { type: Number },
    minBoxHeight: { type: Number },
    maxBoxHeight: { type: Number },
  },
};
</script>
