<template>
  <v-card
    data-draft="form"
    :data-draft-event-title="value.title"
    :data-draft-event-date="dataDate"
    :data-draft-event-day="value.when.day"
    :data-draft-event-time="value.when.time"
    :data-draft-event-duration="value.when.duration"
    :data-draft-event-isInPerson="value.isInPerson"
    :data-draft-event-isVirtual="value.isVirtual"
    :data-draft-event-instructor="value.instructor"
    :disabled="disabled"
    @keydown.esc="close"
    @keydown.enter="save"
    @keydown.space="toggleCheckbox"
  >
    <v-toolbar dense flat color="grey lighten-3">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              tabindex="-1"
              id="more"
              ref="menu"
              v-show="eventId"
              :disabled="!eventId"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item
                id="copySeries"
                ref="copySeries"
                @click="$emit('copy')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("copy") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                id="deleteSeries"
                ref="deleteSeries"
                @click="$emit('delete')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("delete") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-btn tabindex="-1" id="close" ref="close" icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-form ref="form" :spellcheck="false">
      <v-container>
        <!-- title -->
        <v-row dense no-gutters>
          <v-col cols="1" />
          <v-col>
            <v-text-field
              :autofocus="autofocus"
              id="title"
              ref="title"
              class="text-h5"
              :placeholder="$t('add title')"
              v-model="title"
            />
          </v-col>
        </v-row>

        <!-- date/day/time -->
        <v-row dense no-gutters>
          <v-col cols="1">
            <v-icon>mdi-calendar</v-icon>
          </v-col>

          <v-col>
            <DayTimePicker
              :range="range"
              :date="date"
              :day="day"
              :time="time"
              :duration="duration"
              @update:date="setDate"
              @update:day="setDay"
              @update:time="setTime"
              @update:duration="setDuration"
            />
          </v-col>
        </v-row>

        <!-- location -->
        <v-row dense no-gutters>
          <v-col cols="1" />
          <v-col>
            <v-row no-gutters>
              <v-col>
                <v-checkbox
                  id="isInPerson"
                  ref="inPerson"
                  dense
                  prepend-icon="mdi-human-male-female"
                  :label="$t('in person')"
                  value
                  :input-value="isInPerson"
                  @click="isInPerson = !isInPerson"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  id="isVirtual"
                  ref="virtual"
                  dense
                  prepend-icon="mdi-access-point"
                  :label="$t('virtual')"
                  value
                  :input-value="isVirtual"
                  @click="isVirtual = !isVirtual"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- instructor -->
        <v-row dense no-gutters>
          <v-col cols="1">
            <v-icon>mdi-account-circle</v-icon>
          </v-col>

          <v-col>
            <StaffAutocomplete
              id="instructor"
              v-model="instructor"
              :placeholder="$t('optional instructor')"
              :items="staff"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-actions class="justify-end">
      <v-btn
        id="save"
        ref="save"
        depressed
        v-text="$t('save')"
        @click="save"
        class="text-none text-body-2 font-weight-medium"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import DayTimePicker from "./DayTimePicker";
import StaffAutocomplete from "./StaffAutocomplete";
export default {
  name: "EventForm",
  components: {
    DayTimePicker,
    StaffAutocomplete,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    autofocus: { type: Boolean },
    disabled: { type: Boolean },
    eventId: { type: String },
    range: { type: Object },
    staff: { type: Array },
    date: { type: Date },
    value: {
      type: Object,
      default: () => ({
        when: { duration: 60 },
      }),
    },
  },
  computed: {
    dataDate() {
      return this.date ? moment(this.date).format("YYYY-MM-DD") : undefined;
    },
    title: {
      get() {
        return this.value.title;
      },
      set(title) {
        const value = _.cloneDeep(this.value);
        Object.assign(value, { title });
        this.$emit("input", value);
      },
    },
    day() {
      return this.value.when.day;
    },
    time() {
      return this.value.when.time;
    },
    duration() {
      return this.value.when.duration;
    },
    isInPerson: {
      get() {
        return this.value.isInPerson;
      },
      set(isInPerson) {
        const value = _.cloneDeep(this.value);
        Object.assign(value, { isInPerson });
        this.$emit("input", value);
      },
    },
    isVirtual: {
      get() {
        return this.value.isVirtual;
      },
      set(isVirtual) {
        const value = _.cloneDeep(this.value);
        Object.assign(value, { isVirtual });
        this.$emit("input", value);
      },
    },
    instructor: {
      get() {
        return this.value.instructor;
      },
      set(instructor) {
        const value = _.cloneDeep(this.value);
        Object.assign(value, { instructor });
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setDate(date, day) {
      this.$emit("update:date", date);
      this.setDay(day);
    },
    setDay(day) {
      const value = _.cloneDeep(this.value);
      Object.assign(value.when, { day });
      this.$emit("input", value);
    },
    setTime(time, duration) {
      const value = _.cloneDeep(this.value);
      Object.assign(value.when, { time, duration });
      this.$emit("input", value);
    },
    setDuration(duration) {
      const value = _.cloneDeep(this.value);
      Object.assign(value.when, { duration });
      this.$emit("input", value);
    },
    toggleCheckbox() {
      switch (document.activeElement.id) {
        case "isInPerson":
          this.isInPerson = !this.isInPerson;
          return;
        case "isVirtual":
          this.isVirtual = !this.isVirtual;
          return;
      }
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$emit("save");
      this.$refs.form.resetValidation();
    },
    close() {
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
};
</script>
